define("discourse/plugins/discourse-events/discourse/components/events-category-chooser", ["exports", "@ember/object", "select-kit/components/category-chooser"], function (_exports, _object, _categoryChooser) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EventsCategoryChooser extends _categoryChooser.default {
    get content() {
      return super.content;
    }
    static #_ = (() => dt7948.n(this.prototype, "content", [(0, _object.computed)("selectKit.filter", "selectKit.options.scopedCategoryId", "selectKit.options.prioritizedCategoryId", "client")]))();
    categoriesByScope() {
      const categories = super.categoriesByScope();
      if (this.client === "discourse_events") {
        return categories.filter(category => category.events_enabled);
      } else {
        return categories;
      }
    }
  }
  _exports.default = EventsCategoryChooser;
});