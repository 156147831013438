define("discourse/plugins/discourse-events/discourse/lib/events", ["exports", "I18n"], function (_exports, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contentsMap = contentsMap;
  _exports.convertName = convertName;
  function convertName(string) {
    return string.replace(/[_\-]+/g, " ").toLowerCase();
  }
  function contentsMap(items) {
    let key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    return items.map(item => {
      return {
        id: item,
        name: key ? _I18n.default.t(`${key}.${item}`) : convertName(item)
      };
    });
  }
});